<template>
  <main>
    <div
      class="modal fade"
      id="modalVacunas"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Registro de vacunas
            </h5>
            <button
              type="button"
              class="close"
              @click="cerrar();"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-waves ">
              <div class="card-header bg-light text-dark"><i class="fa fa-book fa-fw text-primary"></i>Datos de la Vacuna</div>
              <div class="card-body">
                <div class="row">
                  <div class="form-group col-lg-12 col-md-12 col-sm-12">
                    <label class="form-label"
                      ><i class="fas fa-poll-h fa-fw"></i>Servicio
                    </label>
                    <v-select-servicio
                      v-model="vacuna.servicio"
                      mode="object"
                      :validator="$v.vacuna.servicio"
                      :class="{
                        'is-invalid':
                          $v.vacuna.servicio.$error && $v.vacuna.servicio.$dirty,
                      }"
                    ></v-select-servicio>
                    <span
                      class="invalid-feedback"
                      v-if="!$v.vacuna.servicio.required"
                    >
                      Este campo es requerido
                    </span>
                  </div>
                  <div class="form-group col-lg-12 col-md-12 col-sm-12">
                    <label class="form-label">Nombre</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="vacuna.nombre"
                      :class="{
                        'is-invalid':
                          $v.vacuna.nombre.$error && $v.vacuna.nombre.$dirty,
                      }"
                    />
                  </div>
                  <div class="form-group col-lg-4 col-md-4 col-sm-12">
                    <label class="form-label">Nombre abreviado</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="vacuna.abbr_name"
                      :class="{
                        'is-invalid':
                          $v.vacuna.abbr_name.$error && $v.vacuna.abbr_name.$dirty,
                      }"
                    />
                  </div>
                  <div class="form-group col-lg-8 col-md-8 col-sm-12">
                    <label class="form-label">Enfermedad</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="vacuna.enfermedad"
                      :class="{
                        'is-invalid':
                          $v.vacuna.enfermedad.$error &&
                          $v.vacuna.enfermedad.$dirty,
                      }"
                    />
                  </div>
                  <div class="form-group col-lg-4 col-md-4 col-sm-12">
                    <label class="form-label">Sexo</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="vacuna.sexo"
                      :class="{
                        'is-invalid':
                          $v.vacuna.sexo.$error && $v.vacuna.sexo.$dirty,
                      }"
                    >
                      <option value="A">A</option>
                      <option value="F">F</option>
                      <option value="D">D</option>
                    </select>
                  </div>
                  <div class="form-group col-lg-4 col-md-4 col-sm-12">
                    <label class="form-label">Gestante</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="vacuna.gestante"
                      :class="{
                        'is-invalid':
                          $v.vacuna.gestante.$error && $v.vacuna.gestante.$dirty,
                      }"
                    >
                      <option value="S">S</option>
                      <option value="N">N</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4">
              <div class="card-header bg-light">
                <i class="fas fa-syringe fa-fw text-primary"></i> Datos de la Dosis
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="form-group col-md-6">
                    <label  class="form-label">Dosis </label>
                    <input type="text" class="form-control form-control-sm"  v-model="item.dosis" :class="{'is-invalid':$v.item.dosis.$error && $v.item.dosis.$dirty}" >
                  </div>
                  <div class="form-group col-md-6">
                    <label  class="form-label">Nombre Dosis </label>
                    <input type="text" class="form-control form-control-sm"  v-model="item.nombre_dosis" :class="{'is-invalid':$v.item.nombre_dosis.$error && $v.item.nombre_dosis.$dirty}" >
                  </div>
                  <div class="col-lg-3 col-md-6 col-sm-12">
                    <label class="form-label">Edad Inicio</label>
                    <input type="number" class="form-control form-control-sm" v-model="item.edad_inicio" :class="{'is-invalid':$v.item.edad_inicio.$error && $v.item.edad_inicio.$dirty}">
                  </div>
                  <div class="col-lg-3 col-md-6 col-sm-12">
                    <label class="form-label">Tipo Edad</label>
                    <select  v-model="item.tipo_edad_inicio"   class="form-control form-control-sm" :class="{'is-invalid':$v.item.tipo_edad_inicio.$error && $v.item.tipo_edad_inicio.$dirty}" >
                        <option value="A">AÑOS</option>
                        <option value="M">MESES</option>
                        <option value="D">DIAS</option>
                    </select>
                  </div>
                  <div class="col-lg-3 col-md-6 col-sm-12">
                    <label class="form-label">Edad Final</label>
                    <input type="number" class="form-control form-control-sm"  v-model="item.edad_final" :class="{'is-invalid':$v.item.edad_final.$error && $v.item.edad_final.$dirty}">
                  </div>
                  
                  <div class="col-lg-3 col-md-6 col-sm-12">
                    <label class="form-label">Tipo Edad Final</label>
                    <select  v-model="item.tipo_edad_final"   class="form-control form-control-sm" :class="{'is-invalid':$v.item.tipo_edad_final.$error && $v.item.tipo_edad_final.$dirty}" >
                        <option value="A">AÑOS</option>
                        <option value="M">MESES</option>
                        <option value="D">DIAS</option>
                    </select>
                  </div>
                  <div class=" mt-2 col-md-2  ">
                      <button class="btn btn-primary btn-sm " @click="agregarDosis()">
                          <i class="fas fa-plus"></i>&nbsp;Agregar
                      </button>
                  </div>
                  <div class="table-responsive mt-4">
                    <table class="table table-striped table-bordered table-sm">
                        <tr class="bg-light">
                            <th>Dosis</th>
                            <th>Nombre</th>
                            <th>Rango edad</th>
                            <th></th>
                        </tr>
                        <tr v-for="(item,index) in items" :key="item.id" >
                            <td>{{item.dosis}}</td>
                            <td>{{ item.nombre_dosis }}</td>
                            <td>{{ item.edad_inicio}} {{item.tipo_edad_inicio}} hasta 
                              {{item.edad_final}} {{item.tipo_edad_final}}
                            </td>
                            <td class="text-center">
                              <button class="btn btn-outline-danger btn-sm"  @click="removerDosis(index,item)">
                                  <i class="fas fa-trash-alt"></i>
                              </button>
                            </td>
                        </tr>                                
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4">
              <div class="card-header bg-light">
                <i class="fas fa-warning fa-fw text-primary"></i> Contraindicaciones
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="form-group col-md-12">
                    <label  class="form-label">Contraindicación </label>
                    <input type="text" class="form-control form-control-sm"  v-model="item_contraindicacion.contraindicacion" :class="{'is-invalid':$v.item_contraindicacion.contraindicacion.$error && $v.item_contraindicacion.contraindicacion.$dirty}" >
                  </div>
                  <div class=" mt-2 col-md-2  ">
                      <button class="btn btn-primary btn-sm " @click="agregarContraindicaciones()">
                          <i class="fas fa-plus"></i>&nbsp;Agregar
                      </button>
                  </div>
                  <div class="table-responsive mt-4">
                    <table class="table table-striped table-bordered table-sm">
                        <tr class="bg-light">
                          <th>Contraindicación</th>
                          <th></th>
                        </tr>
                        <tr v-for="(item,index) in contraindicaciones" :key="item.id" >
                          <td>{{item.contraindicacion}}</td>
                          <td class="text-center">
                            <button class="btn btn-outline-danger btn-sm"  @click="removerContraindicacion(index,item)">
                                <i class="fas fa-trash-alt"></i>
                            </button>
                          </td>
                        </tr>                                
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary btn-sm"
              @click="save()"
            >
              Guardar<i class="fa fa-save fa-fw"></i>
            </button>
            <button
              type="button"
              class="btn btn-light btn-sm"
              @click="cerrar()"
            >
              Cerrar<i class="fa fa-times-circle fa-fw"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import vacunaService from "../../../../services/vacunaService";
import {isEmpty} from "lodash";
import Toast from "../../../../components/common/utilities/toast";
import { required } from "vuelidate/lib/validators";
import $ from "jquery";
import vSelectServicio from "../../../../components/common/VSelectServicio";

export default {
  components: { vSelectServicio },
  data() {
    return {
      vacuna: {
        id: "",
        nombre: "",
        abbr_name: "",
        enfermedad: "",
        sexo: "",
        gestante: "",
        servicio: {},
      },
      item:{
        dosis:'',
        nombre_dosis: '',
        edad_inicio: '',
        tipo_edad_inicio: '',
        edad_final: '',
        tipo_edad_final: ''
      },
      item_contraindicacion:{
        contraindicacion:''
      },
      contraindicaciones:[],
      items: [],
      type: "store",
      dosis_eliminar:[],
      contraindicacion_eliminar:[]
    };
  },
  validations() {
    return {
      vacuna: {
        nombre: { required },
        abbr_name: { required },
        enfermedad: { required },
        sexo: { required },
        gestante: { required },
        servicio: { required },
      },
      item:{
        dosis:{required},
        nombre_dosis: {required},
        edad_inicio: {required},
        tipo_edad_inicio: {required},
        edad_final: {required},
        tipo_edad_final: {required}
      },
      item_contraindicacion:{
        contraindicacion: {required},
      }
      
    };
  },
  methods: {
    agregarDosis() {
      this.$v.item.$touch();
      if (this.$v.item.$invalid) {
        return;
      }
      this.items.push({...this.item});
      this.item={};
    },
    agregarContraindicaciones() {
      this.$v.item_contraindicacion.$touch();
      if (this.$v.item_contraindicacion.$invalid) {
        return;
      }
      this.contraindicaciones.push({...this.item_contraindicacion});
      this.item_contraindicacion={};
    },
    removerDosis(index,dosis) {
      if(this.type==="edit"){ 
        this.dosis_eliminar.push(dosis);
      }else{
        this.dosis_eliminar=[];
      }
      this.items.splice(index, 1);
    },
    removerContraindicacion(index,contraindicacion) {
      if(this.type==="edit"){ 
        this.contraindicacion_eliminar.push(contraindicacion);
      }else{
        this.contraindicacion_eliminar=[];
      }
      this.contraindicaciones.splice(index, 1);
    },
    async save() {
      try {
        this.$v.vacuna.$touch();
         if (isEmpty(this.items)) {
          Toast.fire({
            icon: 'error',
            title: 'Debe agregar por lo menos una dosis'
          });
          return;
        }
        if (this.$v.vacuna.$invalid) {
          return;
        }

        this.LoaderSpinnerShow();

        let datos={
          ...this.vacuna,
          dosis:this.items,
          dosis_eliminar:this.dosis_eliminar,
          contraindicacion_eliminar:this.contraindicacion_eliminar,
          contraindicaciones:this.contraindicaciones
        }

        if (this.type === "store") {
          await vacunaService.store(datos);
          this.cerrar();
        } else if (this.type === "edit") {
          await vacunaService.update(datos);
          this.cerrar();
        }

        this.LoaderSpinnerHide();

        Toast.fire({
          icon: "success",
          title: "Datos guardados con exito",
        });
        this.$emit("cargar-vacunas");

        $("#modalVacunas").modal("hide");
        
      } catch (err) {
        console.error(err);
        this.LoaderSpinnerHide();
        Toast.fire({
          icon: "error",
          title: "Ocurrio un error al procesar la solicitud",
        });
      }
    },
    async limpiarCampos() {
      this.vacuna.nombre = "";
      this.vacuna.abbr_name = "";
      this.vacuna.enfermedad = "";
      this.vacuna.sexo = "";
      this.vacuna.gestante = "";
      this.vacuna.servicio = {};
      this.items=[];
      this.dosis_eliminar=[],
      this.contraindicaciones=[];
      this.contraindicacion_eliminar=[]
    },
    cerrar() {
      this.type = "store";
      this.limpiarCampos();
      $("#modalVacunas").modal("hide");
    },
    async edit(id) {
      this.$nextTick(async () => {
        this.type = "edit";
        $("#modalVacunas").modal("show");
        const response = await vacunaService.show(id);
        this.vacuna.id = response.data.id;
        this.vacuna.cups = response.data.cups;
        this.vacuna.nombre = response.data.nombre;
        this.vacuna.abbr_name = response.data.abbr_name;
        this.vacuna.enfermedad = response.data.enfermedad;

        this.vacuna.sexo = response.data.sexo;
        this.vacuna.gestante = response.data.gestante;

        this.vacuna.servicio = {
          id: response.data.servicio.id,
          codigo: response.data.servicio.codigo,
          label: `(${response.data.servicio.codigo}) ${response.data.servicio.descripcion} `,
          descripcion: response.data.servicio.descripcion,
        };

        this.items = response.data.dosis.map(x=>{
          return {
              id_dosis:x.id,
              dosis:x.dosis,
              nombre_dosis:x.nombre_dosis,
              edad_inicio:x.edad_ideal_inicio,
              tipo_edad_inicio:x.tipo_edad_ideal_inicio,
              edad_final:x.edad_ideal_final,
              tipo_edad_final:x.tipo_edad_ideal_final,
          }
        });

        this.contraindicaciones = response.data.contraindicaciones.map(x=>{
          return {
            id_contraindicacion:x.id,
            contraindicacion:x.contraindicacion
          }
        });

      });
    },
  },
};
</script>

