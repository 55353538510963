import Service from './Service';

const resource = '/api/servicio';

export default {
    getByCodigo(codigo, tarifa = '') {
        let url = `${resource}/get?by=codigo&s=${codigo}`;
        url += tarifa !== '' ? `&tarifa=${tarifa}` : '';
        return Service.get(url);
    },
    index(search = '', pagination = {}){

        const hasAditionalParams = !!pagination['params'];

        let aditionalParams = {};

        if(hasAditionalParams){
            for(const param in pagination['params']){
                if(
                    pagination['params'][param] !== ''
                    && pagination['params'][param] !== undefined
                    && pagination['params'][param] !== null
                ){
                    aditionalParams[param] = pagination['params'][param]
                }
            }
        }

        const params = {
            ...(search !== '' ? {s:search} : {}),
            ...(pagination['per_page'] ? {per_page : pagination['per_page']} : {}),
            ...(pagination['page'] ? {page : pagination['page']} : {}),
            ...(hasAditionalParams ? {...aditionalParams} : {}),
        };

        return Service.get(`${resource}`, {
            params : {
                ...params
            }
        });
    },
    search (query) {
        return Service.get(`${resource}/search?s=${query}`);
    },
    show(id){
        return Service.get(`${resource}/show/${id}`);
    },
    store(service){
        return Service.post(`${resource}/store`, service);
    },
    update(service){
        return Service.put(`${resource}/update`, service);
    },
    isUnique(code){
        return Service.get(`${resource}/is-unique/${code}`);
    }
}
